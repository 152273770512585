import React, { Fragment } from "react";
import Image from "next/image";
import assets1 from "../../assets/images/r.jpg";
import assets2 from "../../assets/images/ne.jpg";
import assets3 from "../../assets/images/br.jpg";
import assets4 from "../../assets/images/er.jpg";
import assets5 from "../../assets/images/co.jpg";
import assets6 from "../../assets/images/ch.jpg";

import styles from "@/styles/shopbycategory.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { homeLinks } from "@/data/home";
import Link from "next/link";

SwiperCore.use([Navigation, Pagination]);

const ShopByCategory = () => {
  const slides = [];

  return (
    <Fragment>
      <section className={styles.shopbycategory}>
        <div className="container">
          <div className={styles.shopbycategoryheader}>
            <h1 className={styles.shopbycategoryheader_head}>
              {homeLinks.shopByCategory.title}
            </h1>
          </div>
          <Swiper
            className="swiper-navigation-black pb-4"
            spaceBetween={0}
            slidesPerView={6}
            pagination={false}
            navigation={{
              prevEl: ".swiper-button-prev-1",
              nextEl: ".swiper-button-next-1",
            }}
            modules={[Pagination, Navigation]}
            centeredSlides={false}
            breakpoints={{
              1199: {
                slidesPerView: 6,
              },
            }}
          >
            {homeLinks.shopByCategory.sliders.map((v: any, i) => (
              <SwiperSlide key={i}>
                <Link href={v.link} passHref>
                  <a className="shopbycategory_image">
                    <Image
                      src={v.image}
                      className="img-fluid"
                      alt={v.alt}
                      height={163}
                      width={163}
                    />
                    <p>{v.name}</p>
                  </a>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            className={`${
              styles.swiper_button_prev_1
            } ${"swiper-button-prev-1 bi bi-arrow-left-circle"}`}
          ></div>
          <div
            className={`${
              styles.swiper_button_next_1
            } ${"swiper-button-next-1 bi bi-arrow-right-circle"}`}
          ></div>
        </div>
      </section>
    </Fragment>
  );
};

export default ShopByCategory;
